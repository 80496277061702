<template>
  <div>
    <div v-if="$hasPermissions(clientSession, ['ASSET_SUMMARY_REPORT'], 1)">
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
        >{{ pageheading.toLocaleUpperCase() }}</h1>
      </div>
      <div style="max-width: 95%; margin: auto;">

        <div id="body-content-area">
          <div :class="{ 'hidden': !isLoading }">
            <Loading />
          </div>
          <div :class="{ 'visible': isLoading }">

          <div class="top-bar-spacer" style="height: 8px; width: 100%;">&nbsp;</div>

          <div>
            <div style="display: flex; justify-content: flex-end; margin-bottom: 1rem;" class="control grid-button top-bar">
              <div id="buttongroup">
              <div class="level">
                <div class="level-left">
                  <div class="field is-grouped">
                    <div class="control">
                      <button class="button is-accent" @click="$router.push({ name: 'AssetSummaryReportingGenerate', params: {  }})">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          Generate Summary Asset Reports
                        </span>
                      </button>
                    </div>
                    <div class="control">
                      <button :disabled="isLoadingAssetReports" class="button is-light" @click="getAssetReports">
                        <span class="icon">
                          <i v-if="!isLoadingAssetReports" class="fas fa-sync-alt"></i>
                          <i v-else class="fas fa-spinner fa-spin"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div>
            <div ref="divWrapper">
              <Grid
              v-if="maxHeight > 0"
              :style="{height: maxHeight + 'px'}"
              :filter="filter"
              :data-items="institutionReports"
              :selected-field="selectedField"
              :sortable="true"
              :sort="sort"
              :filterable="true"
              :pageable="{
                ...pageable,
                pageSizes: pageSizes
              }"
              :page-size="pageSize"
              :skip="pagination.skip"
              :take="pagination.take"
              :total="pagination.total"
              :columns="columns"
              @pagechange="pageChangeHandler"
              @sortchange="sortChangeHandler"
              @filterchange="filterChangeHandler"
              @selectionchange="onSelectionChange"
              @headerselectionchange="onHeaderSelectionChange"
            >
              <template v-slot:downloadTemplate="{props}">
                <td class="has-text-centered">
                  <a class="download" :href="`${props.dataItem.path}`">
                    <i class="fas fa-download"></i>
                  </a>
                  &nbsp;
                  <a class="download" :href="'#'" @click.prevent="viewReport(props.dataItem)">
                    <i class="fas fa-eye"></i>
                  </a>
                </td>
              </template>
              <template v-slot:batchNameCell="{props}">
                <td>
                  <a class="link" href="javascript:void()" @click.prevent="$router.push({
                    name: 'AssetSummaryReportingBatchView',
                    params: {
                      id: props.dataItem.id
                    }
                  })">
                    {{ props.dataItem.batchName.replace(' Bank Asset Summary Reports', '') }}
                  </a>
                </td>
              </template>
              <template v-slot:headerCell="{props}">
                <td
                  style="cursor: pointer!important; background-color: #f5f5f5!important;"
                  @click.prevent="() => {
                    if (props.dir === undefined) props.dir = 'asc';
                    else if (props.dir === 'asc') props.dir = 'desc';
                    else props.dir = undefined;

                    sortChangeHandler({
                      sort: [{
                        field: props.field,
                        dir: props.dir
                      }]
                    })
                  }"
                >
                  <a href="javascript:void()">
                    <span v-if="props.dir === 'asc' && sort.find(_rec => _rec.field === 'batchName')" class="k-icon k-i-sort-asc-sm"></span>
                    <span v-if="props.dir === 'desc' && sort.find(_rec => _rec.field === 'batchName')" class="k-icon k-i-sort-desc-sm"></span>
                    <span style="color: black;">{{ props.title }}</span>
                  </a>
                </td>
              </template>
              <template v-slot:generationCell="{props}" >
              <td style="">
                  {{ props.dataItem.stats.inQueue }}
                </td>
              </template>
              <template v-slot:ReadyCell="{props}" >
                <td style="">
                  {{ props.dataItem.stats.generated }}
                </td>
              </template>
              <template v-slot:FailedCell="{props}" >
                <td style="">
                  {{ props.dataItem.stats.failed }}
                </td>
              </template>
              <template v-slot:ProgressCell="{props}" >
                <td style="">
                  {{ ((props.dataItem.stats.generated / props.dataItem.stats.scheduled) * 100).toFixed(2) }}% of {{ props.dataItem.stats.scheduled }}
                </td>
              </template>
              <template v-slot:reportTypeFilterSlotTemplate="{props, methods}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input type="text" class="k-textbox" :id="`${props.field}`" :value="props.value" @input="(ev) => {methods.change({operator: 'contains', field: props.field, value: ev.target.value, syntheticEvent: ev});}">
                  </div>
                </div>
              </template>
            </Grid>
            </div>
          </div>

          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
              <div class="modal-background" @click="showDeleteModal = false"></div>
              <div class="modal-card">
                <header class="modal-card-head has-bg-danger">
                  <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                  <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
                </header>
                <section class="modal-card-body">
                  <div class="content">
                    Type "<b>delete</b>" to confirm that you want to delete one or more items.
                  </div>
                  <div class="field">
                    <div class="control">
                      <input type="text" class="input" placeholder="type `delete` and then click confirm" v-focus
                        v-model.trim="confirmationText" @keydown.enter.prevent="deleteIfConfirmed" />
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <a class="button is-danger" @click="deleteIfConfirmed" :disabled="confirmationText !== 'delete'">
                    <span class="icon">
                      <i class="fal fa-trash-alt"></i>
                    </span>
                    <span>Confirm</span>
                  </a>
                  <a class="button" @click="showDeleteModal = false">Cancel</a>
                </footer>
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
    <div v-else>
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
        >No Permission</h1>
      </div>

      <center>
        <b>You Lack The Permissions Required To View This Page</b>
      </center>
    </div>
  </div>
</template>

<script>
//* ***** this is grid native example (there is a wrapper version) *******
import Vue from 'vue'
import Loading from '../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'

import moment from 'moment'

let pageName = 'Summary Asset Reporting - Period Summary'

Vue.filter('formatDate', function (date) {
  if (date) {
    return moment(new Date(date).toISOString().substring(0, 10)).format('MMM D, YYYY')
  }
})

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

export default {
  components: {
    Loading,
    'Grid': Grid
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      dates: {
        start: {
          minDate: new Date(1969, 0, 1),
          maxDate: new Date(moment().toDate())
        },
        end: {
          minDate: new Date(moment().toDate()),
          maxDate: new Date(moment().toDate())
        },
        currentDateRange: { start: new Date(moment().year(), 0, 1), end: new Date(moment().toDate()) }
      },
      reportDateRanges: [
        { text: 'Current Year', value: '1' },
        { text: 'Last Year', value: '2' },
        { text: 'Custom Date Range', value: '3' },
        { text: 'All', value: '4' }
      ],
      selectedReportDateRange: '1',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      isLoadingAssetReports: false,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      payload:
      {
        filter:
        {
          allReports: false,
          institutionId: 0,
          dateFilter:
          {
            startDate: null,
            endDate: null
          }
        },
        limit: 20,
        offset: 0
      },
      input: {
        name: '',
        reportType: '',
        user: '',
        institution: '',
        startDate: '',
        endDate: ''
      },
      reports: [],
      confirmationText: '',
      isLoading: true,
      showDeleteModal: false,
      interval: null,
      countdownTimer: 5,
      countdown: null,
      selectedField: 'selected',
      selectedRows: [],
      pageheading: pageName,
      sort: [
        { field: 'batchName', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: []
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'batchName',
          title: 'Period',
          headerCell: 'headerCell',
          width: '320px',
          cell: 'batchNameCell',
          filterable: true,
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'stats',
          title: 'Generating',
          cell: 'generationCell',
          filterable: false,
          sortable: false
        },
        {
          title: 'Failed',
          cell: 'FailedCell',
          filterable: false,
          sortable: false
        },
        {
          title: 'Ready',
          cell: 'ReadyCell',
          filterable: false,
          sortable: false
        },
        {
          title: 'Progress',
          cell: 'ProgressCell',
          filterable: false,
          sortable: false
        }
      ],
      pagination: {
        take: 10,
        skip: 0,
        total: 0
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    institutionReports: function () {
      if (this.reports.length === 0) { return [] }

      let filtered = filterBy(this.reports, this.filter)
      return orderBy(filtered, this.sort)
    },
    areAllSelected () {
      let selected = this.reports.findIndex(item => item.selected === false)
      let isUndefined = this.reports.findIndex(item => item.selected === undefined)

      return selected === -1 && isUndefined === -1
    },
    columns () {
      return [
        // { field: 'selected', width: '28px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    },
    hideDateLabel () {
      return this.selectedReportDateRange !== '3' && this.selectedReportDateRange !== '4'
    },
    pageSizes () {
      let sizes = [this.minResults]

      sizes.push(this.minResults)
      sizes.push(5)
      sizes.push(10)
      sizes.push(15)
      sizes.push(20)
      sizes.push(50)
      sizes.push(100)

      // remove dups
      sizes = [...new Set(sizes)]

      // return a sorted array from smallest to largest
      return sizes.sort((a, b) => a - b)
    }
  },
  mounted () {
    this.__setup()
  },
  unmounted () {
    clearInterval(this.interval)
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach(element => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) { filterInput.value = element.value }
      })
    }
  },
  methods: {
    viewReport (report) {
      this.$router.push({ name: 'ViewReport', params: { id: report.id, 'report': report } })
    },
    deleteIfConfirmed (event, data) {
      if (this.confirmationText === 'delete') this.deleteSelected('/reports')
    },
    onHeaderSelectionChange (event) {
      // Do nothing
    },
    onSelectionChange (event) {
      // Do nothing
    },
    stripDate: function (dateString) {
      // 2016-02-24T00:11:00Z to 2016-02-24
      // Note this is needed to prevent off-by-one date errors due to the timestamp and timezone offset
      return dateString.substring(0, 10)
    },
    pageChangeHandler: function (event) {
      this.pagination.skip = event.page.skip
      this.pagination.take = event.page.take
      this.perPage = event.page.take
      this.pageSize = event.page.take

      localStorage.setItem('reportPageSize', event.page.take)
      this.getAssetReports()
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('reportSort', JSON.stringify(this.sort))
      this.getAssetReports()
    },
    change: function (filter) {
      if (filter.syntheticEvent) { this.filter = filter.syntheticEvent.filter }
    },
    filterChangeHandler: debounce(function (e) {
      if (e.filter && !this.filters) {
        this.filters = e.filter.filters
      } else if (e.filter && this.filters) {
        e.filter.filters.forEach(element => {
          this.filters.replaceOrPush(element, function (e) {
            return e.field === element.field
          })
        })
      } else {
        if (e.event.value === '') { this.filters = this.filters.filter(x => x.field !== e.event.field) }
      }

      localStorage.setItem('listreportFilters', JSON.stringify(this.filters))

      this.skip = 0
      this.getAssetReports(false)
    }, 500),
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.pagination.skip = 0
      this.sort = [
        { field: 'uploadDate', dir: 'asc' }
      ]

      localStorage.removeItem('listreportFilters')
      localStorage.removeItem('reportSort')

      this.getAssetReports(false)
    },
    onStartDateRangeChange: function (e) {
      // Do nothing
    },
    onEndDateRangeChange: function (e) {
      // Do nothing
    },
    onReportDateRangeSelect: async function (e) {
      // Do nothing
    },
    getAssetReports () {
      this.isLoadingAssetReports = true
      let batchNameFilter = this.filters ? this.filters.find(x => x.field === 'batchName') : {}
      this.api().assetReports.listAssetReportBatches({
        take: this.pagination.take,
        skip: this.pagination.skip,
        sort: this.sort[0].field,
        desc: this.sort[0].dir,
        batchName: `${batchNameFilter ? batchNameFilter.value : ''}`.replace('undefined', ''),
        type: 'AssetSummaryReport'
      }, (error, result) => {
        if (error) {
          this.handleApiErr(error)
        } else {
          this.reports = result.records
          this.pagination.total = result.pagination.totalRecords
          this.$forceUpdate()
        }

        this.isLoading = false
        this.isLoadingAssetReports = false
      })
    },
    setupReportDisplay () {
      let that = this
      this.$nextTick(() => {
        let elements = [
          '.has-text-centered.has-background-primary',
          '.top-bar',
          '.top-bar-spacer',
          '.bottom-bar',
          '.bottom-bar-spacer'
        ]

        let func = async (results) => {
          let [numberOfResults, maxHeight] = results

          if (this.minResults && this.maxHeight && (numberOfResults === null || maxHeight === null)) {
            return
          }

          this.pagination.take = numberOfResults
          this.pagination.perPage = numberOfResults
          this.pagination.minResults = numberOfResults
          this.maxHeight = maxHeight

          this.getAssetReports()
        }

        that.getListHeights('body-content-area', 40, 79, elements, func.bind(this))

        window.addEventListener('resize', () => {
          that.getListHeights('body-content-area', 40, 79, elements, func.bind(that))
        })
      })
    },
    async consumeLocalStorage () {
      if (localStorage.reportSkip) {
        this.skip = JSON.parse(localStorage.getItem('reportSkip'))
        localStorage.removeItem('reportSkip')
      }

      // if (localStorage.reportFilters) { this.filters = JSON.parse(localStorage.getItem('reportFilters')) }

      // if (localStorage.reportSort) { this.sort = JSON.parse(localStorage.getItem('reportSort')) }

      // if (localStorage.reportSelection) { await this.loadQueryParams(JSON.parse(localStorage.getItem('reportSelection'))) }

      if (localStorage.reportPageSize) {
        let pageSize = JSON.parse(localStorage.getItem('reportPageSize'))
        this.pagination.take = pageSize
        this.pagination.perPage = pageSize
        this.pagination.pageSize = pageSize
      }
    },
    __setup () {
      this.consumeLocalStorage()
      this.setupReportDisplay()
      this.getAssetReports()

      // This is technically a race condition.
      // However, the accuracy of the countdown
      // is not important. It is just a visual
      // this.interval = setInterval(() => {
      //   this.countdownTimer--

      //   // Run the report list every 5 seconds
      //   // This should update and let the user
      //   // know if a report has been completed
      //   if (this.countdownTimer === 0) {
      //     this.getAssetReports()
      //     this.countdownTimer = 5
      //   }
      // }, 1000)
    }
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
  color: #7fb942;
}

.link:hover {
  color: #e69138;
}

.k-link {
  color: #000!important;
}
</style>

<style>
.k-dropdown {
  width: auto!important;
  min-width: 200px;
}

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.k-widget k-dateinput k-state-default k-label{
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.k-date-picker{
  width: 90%;
  margin-right: 6px;
  border: pink;
}
a.download, a.bcc-color{
  color: #7fb942 !important;
}
.page-subtitle{
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.reportDateStatus, .status {
    margin-top: 10px;
}
.k-invalid-msg {
    display: inline-block;
}
.invalid {
    color: red;
}
.valid {
    color: green;
}
.bcc-color{
   color: #7fb942;
}
.grid-button .button {
  margin-bottom: 5px;
}
.k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.hidden {
  display: none;
}

.visible {
  visibility: none;
}

th {
  vertical-align: center;
}

table:not([cellpadding]) th:nth-of-type(2) {
  padding-bottom: 12px!important;
}

.k-header td {
  background-color: #f5f5f5!important;
}

.k-master-row > td:first-child {
  display: flex;
  justify-content: flex-start !important;
}
</style>
